import React, { useState } from 'react';
import { FormUpload } from './style';
import { AppIcon } from '../../global/AppIcon';

export interface FormUploadProps {
  description?: string
  name: string
  register?: any
  required?: boolean
  // eslint-disable-next-line no-unused-vars
  setFilesInForm?:(filesData: { name: string; files: FileList | null }) => void;
}

function getFilesNamesString(arFileNames: string[]): string {
  const filesLengthString = 29;
  let filesString = arFileNames.reduceRight((fileName, currentNames) => `${currentNames}, ${fileName}`, '');
  filesString.replace(/,\s$/, '');
  if (filesString.length > filesLengthString) {
    filesString = `${filesString.substring(0, filesLengthString)}...`;
  }
  return filesString;
}

function AppFormUpload({
  description, name, register, required, setFilesInForm,
}: FormUploadProps) {
  const [fileText, setFileText] = useState(description);
  const title = 'Прикрепить файл';
  function isSafari(): boolean {
    const ua = navigator.userAgent;
    // eslint-disable-next-line no-prototype-builtins,no-mixed-operators
    return /iPad|iPhone|iPod/.test(ua) && !window.hasOwnProperty('MSStream') || /Safari/.test(ua) && !/Chrome/.test(ua);
  }

  return (
    <FormUpload>
      <AppIcon className="file" name="file" width={21} height={21} />
      <div className="info">
        <div className="title">{title}</div>
        <div className="description" dangerouslySetInnerHTML={{ __html: fileText || '' }} />
      </div>
      <input
        {...register(name, { required })}
        type="file"
        multiple
        name={name}
        onChange={() => {
          const fileInput = document.getElementsByName(name)[0] as HTMLInputElement | null;
          if (fileInput != null && fileInput.files != null) {
            const arFileNames = [];
            for (let i = 0; i < fileInput.files.length; i += 1) {
              arFileNames.push(fileInput.files[i].name);
            }
            const fileNames = getFilesNamesString(arFileNames);
            setFileText(fileNames);
            // Костыль для Safari так как не срабатывает register от react-hook-form
            if (isSafari() && setFilesInForm) {
              setFilesInForm({ name, files: fileInput.files });
            }
          } else if (description) {
            setFileText(description);
          } else {
            setFileText('');
          }
        }}
      />
    </FormUpload>
  );
}

AppFormUpload.defaultProps = {
  register: '',
  description: '',
  required: false,
  setFilesInForm: '',
};

export { AppFormUpload };
